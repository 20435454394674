import { ChainId } from '@uniswap/sdk';
import MULTICALL_ABI from './abi.json';

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.BITGERT]: '0xf3a3dAf360161B2f10c645EF039C709A3Fd4Ea62',
  [ChainId.DOGE]: '0xf3a3dAf360161B2f10c645EF039C709A3Fd4Ea62',
  [ChainId.DOKEN]: '0xb5C7882e37359572FD1cCFAA276e7Fdf70145D42',
  [ChainId.FUSE]: '0xf3a3dAf360161B2f10c645EF039C709A3Fd4Ea62',
  [ChainId.QUAI_TESTNET]: '0x00CE15eD37dD47b8923770785DC2E975D96049Ff',
};

export { MULTICALL_ABI, MULTICALL_NETWORKS };
